import React from 'react'
import useStripeStartMatchingPaymentHandler from '../../../../api/thirdParty/stripe/useStripeStartMatchingPaymentHandler'
import { ShowProjectQuery, StripeProduct, useGetStripeProductConfigsQuery } from '../../../../graphql/generated'
import SingleClickButton from '../../../../common/components/SingleClickButton'
import LoadingSpinner from '../../../../common/components/LoadingSpinner'
import { Duration } from 'luxon'
import { useGraphQLDataSource } from '../../../../api/graphql'
import ErrorBlock from '../../../../common/components/ErrorBlock'
import { CheckMarkItemType } from './BasicPaymentCard'
import { IonAccordion, IonAccordionGroup, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonChip, IonItem } from '@ionic/react'
import { CheckMarkItem } from './CheckMarkItem'
import { moneyToText } from '../../../../common/utils/currency'
import Styles from './Payment.module.scss'

type PremiumPaymentCardProps = {
  project: ShowProjectQuery["getProject"],
  taskId?: string,
  isPaymentLoading?: boolean,
}

const PremiumPaymentCard: React.FC<PremiumPaymentCardProps> = ({ project, taskId, isPaymentLoading = false }) => {

  if (taskId === undefined) {
    return <ErrorBlock name="Task ID is required for Premium start matching payment" />
  }

  const checkMarkItems: Array<CheckMarkItemType> = [
    { iconColor: 'success', description: 'Satisfaction money back guarantee' },
    { iconColor: 'success', description: 'Cut costs and save time with Weaver bid levelling service' },
    { iconColor: 'success', description: 'Dedicated Customer success rep assigned to ensure flawless tendering experience' },
  ]

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const getStripeProductConfigsQuery = useGetStripeProductConfigsQuery(gqlDataSource, {}, {
    staleTime: Duration.fromObject({ minutes: 5 }).as('milliseconds'),
  })

  const data = getStripeProductConfigsQuery.data

  const premiumConfig = data?.getStripeProductConfigs.find((config) => config.product === StripeProduct.PremiumStartMatchingPayment)
  const price = moneyToText(premiumConfig?.price)

  const makeStripePayment = useStripeStartMatchingPaymentHandler({
    product: StripeProduct.PremiumStartMatchingPayment,
    project,
    taskId: taskId,
    priceId: premiumConfig?.priceId,
    isPremium: true,
  })

  const handlePaymentClicked = async () => {
    await makeStripePayment()
  }

  if (isPaymentLoading || getStripeProductConfigsQuery.isLoading) {
    return <LoadingSpinner name="PremiumPaymentCard"/>
  }

  return <>
    <IonCard className={`${Styles.paymentCard} ion-padding ion-margin-top`}>
      <p className={Styles.recommendedTag} color="tertiary">Recommended</p>
      <div className='ion-text-center'>
        <IonCardHeader>
          <IonCardTitle>Premium tendering</IonCardTitle>
          <span className={Styles.paymentFee}>
            {price}
          </span>
          <IonCardContent className={Styles.cardContentText}>Perfect for homeowners with no architect involved or for beginners</IonCardContent>
        </IonCardHeader>
        <SingleClickButton disabled={isPaymentLoading} onClick={handlePaymentClicked}>
          Choose Premium tendering
        </SingleClickButton>
      </div>
      <IonAccordionGroup value={[ 'premium' ]}>
        <IonAccordion value='premium'>
          <IonItem slot="header">
            <p className={`${Styles.cardContentText} ${Styles.cardContentTextNoBottomPaddingMargin}`}>Everything in basic: plus</p>
          </IonItem>
          <div slot='content' color='light'>
            {checkMarkItems.map((item, key) => (
              <CheckMarkItem key={key} iconColor={item.iconColor} description={item.description} />
            ))}
          </div>
        </IonAccordion>
      </IonAccordionGroup>
    </IonCard>
  </>
}

export default PremiumPaymentCard
