import { useRef } from "react"
import { useGraphQLDataSource } from "../../../api/graphql"
import { useMyIndividualActiveTeam } from "../../../api/providers/MyIndividualProvider/MyIndividualProvider"
import { useAnalyticsEvent } from "../../../api/providers/SegmentProvider/hooks"
import { useWeaverFlags } from "../../../api/thirdParty/launchDarkly/useWeaverFlags"
import { UploadedFileStatus, useGetProjectDetailsForLeadAcceptanceQuery } from "../../../graphql/generated"
import { getFlexibleProjectBudgetAsText } from "../common"
import { getWorkStartEstimateLabels } from "../workEstimate.i18n"
import { alwaysArray } from "../../../common/utils"

export const useFireAcceptLeadEvent = (leadId: string, paymentAccepted: boolean) => {

  const hasFiredAcceptLeadEvent = useRef(false)
  const myTeam = useMyIndividualActiveTeam()
  const triggerEvent_Project_Lead_Responded_Accepted = useAnalyticsEvent("Project_Lead_Responded_Accepted")
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const weaverFlags = useWeaverFlags()

  useGetProjectDetailsForLeadAcceptanceQuery(gqlDataSource, {
    id: leadId,
  }, {
    refetchOnWindowFocus: false,
    onSuccess: async (data) => {

      const { getProject } = data

      const documentPreviewCount = getProject?.documentPreviews?.filter((doc) => doc && doc.status !== UploadedFileStatus.Archived).length ?? 0
      const activeDocuments = getProject?.documents?.filter(d => d.status !== UploadedFileStatus.Archived) ?? []
      const allDocumentTags = activeDocuments?.filter(d => d.kind !== null).map(d => d.kind).flat() ?? []

      if (!paymentAccepted || hasFiredAcceptLeadEvent.current) {
        return
      }

      hasFiredAcceptLeadEvent.current = true

      await triggerEvent_Project_Lead_Responded_Accepted({
        projectId: getProject.id,
        titleOfProject: getProject.title,
        description: getProject.description,
        budgetShownToContractor: getFlexibleProjectBudgetAsText(getProject, myTeam, weaverFlags['MW-2600-expand-project-creation-flow'], weaverFlags['MW-2645-adjust-flex-budget-ranges']) ?? undefined,
        workStartEstimate: getWorkStartEstimateLabels()[getProject.workStartEstimate],
        /** @deprecated MW-2386-remove-tender-return-date - MAY STILL BE USED BY C.IO AND CAUSE FAILURE IF REMOVED */
        tenderReturnDate: undefined,
        type: getProject.projectTypes,
        // a nonWeaverBuilder can not pass through here so we hardcode false
        isNonWeaverBuilder: false,
        documentPreviewCount,
        allDocumentTags: alwaysArray(allDocumentTags) ,
        documentCount: activeDocuments.length,
      })
    },
  })
}
