import React from 'react'
import { IonBadge, IonButton, IonCard, IonCardContent, IonGrid, IonIcon, IonList, IonRow, IonText, IonThumbnail } from '@ionic/react'
import { DateTime } from 'luxon'
import { useGraphQLDataSource } from '../../api/graphql'
import { TeamType, useListMyProjectsAndLeadsQuery } from '../../graphql/generated'
import Styles from './ProjectsIndexPage.module.scss'
import GlobalHeader from '../../common/components/GlobalHeader/GlobalHeader'
import GlobalHeaderStyles from '../../common/components/GlobalHeader/GlobalHeader.module.scss'
import { useMyIndividualActiveTeam } from '../../api/providers/MyIndividualProvider/MyIndividualProvider'
import { getKnownRoutePathForPage, Page, pageConfig_ShowLead, useRouteTo } from '../../routes'
import WeaverIonPage from '../../common/components/WeaverIonWrappers/WeaverIonPage'
import WeaverIonContent from '../../common/components/WeaverIonWrappers/WeaverIonContent'
import LoadingSpinnerPage from '../../common/components/LoadingSpinner/LoadingSpinnerPage'
import ErrorBlockPage from '../../common/components/ErrorBlock/ErrorBlockPage'
import WeaverIonHeader from '../../common/components/WeaverIonWrappers/WeaverIonHeader'
import { getEnumLabel } from '../../common/utils'
import { getFlexibleProjectBudgetAsMoneyOrMoneyRange, getProjectBudgetDescription } from './common'
import { flexibleBudgetTextToSignificantFigures } from '../../common/utils/budgetTransformation'
import { arrowForwardSharp, barChartOutline, peopleOutline, shieldCheckmarkOutline } from 'ionicons/icons'
import StaticGoogleMap from '../../common/components/StaticGoogleMap/StaticGoogleMap'
import { useWeaverFlags } from '../../api/thirdParty/launchDarkly/useWeaverFlags'
import NextTopLevelTaskDisplay from './NextTopLevelTaskDisplay'
import NextTaskButton from './NextTaskButton'
import { useAnalyticsEvent } from '../../api/providers/SegmentProvider/hooks'
import { EventProperties } from '../../api/providers/SegmentProvider/events'

const NO_LEADS_DESCRIPTION_V2 = 'New leads will show up here. Complete setting up your profile to receive leads.'
const NO_LEADS_DESCRIPTION_V3 = 'New tenders will show up here. Complete setting up your profile to receive tenders.'

const NO_PROJECTS_DESCRIPTION_CONTRACTOR = 'You don\'t have any active projects yet. Accept leads to be put forward to a project shortlist.'
const NO_PROJECTS_DESCRIPTION_CONTRACTOR_V1 = 'You don\'t have any active projects yet. Accept tenders to be put forward to a project shortlist.'

const NO_PROJECTS_DESCRIPTION_OWNER = 'You don\'t have any active projects yet. Your projects will appear here.'

function timeAgo(date: string): string {
  const now = DateTime.local()
  const past = DateTime.fromISO(date)
  const diff = now.diff(past, 'hours')
  return `${Math.floor(diff.hours)} hours ago`
}

const ProjectsIndexPage: React.FC = () => {
  const currentTeam = useMyIndividualActiveTeam()
  const weaverFlags = useWeaverFlags()
  const goToLead = useRouteTo(pageConfig_ShowLead.path)

  type eventTypes = keyof Pick<EventProperties, 'Add_Project_Match_Clicked'| 'Add_Project_QS_Clicked' | 'Add_Project_Vet_Clicked'>

  const myTeam = useMyIndividualActiveTeam()

  const goToCreateProject = useRouteTo(getKnownRoutePathForPage(Page.CreateProject))
  const triggerAdd_Project_Match_Clicked = useAnalyticsEvent('Add_Project_Match_Clicked')
  const triggerAdd_Project_QS_Clicked = useAnalyticsEvent('Add_Project_QS_Clicked')
  const triggerAdd_Project_Vet_Clicked = useAnalyticsEvent('Add_Project_Vet_Clicked')

  const eventsTriggerCase = {
    'Add_Project_Match_Clicked': triggerAdd_Project_Match_Clicked,
    'Add_Project_QS_Clicked': triggerAdd_Project_QS_Clicked,
    'Add_Project_Vet_Clicked': triggerAdd_Project_Vet_Clicked,
  }

  const navigateToCreateProject = async (eventName: eventTypes) => {
    const selectedEvent = eventsTriggerCase[eventName]
    await selectedEvent()
    goToCreateProject({})()
  }

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const { isFetching, error, data, refetch } = useListMyProjectsAndLeadsQuery(gqlDataSource, {}, { refetchOnWindowFocus: false })

  if (isFetching) {
    return <LoadingSpinnerPage name="ProjectsIndex"/>
  }

  if (error || !data) {
    return <ErrorBlockPage name='ProjectsIndexPage' onRefresh={refetch} />
  }

  const projects = data.listMyProjects
  const leads = data.listMyLeads
  const newLeads = leads.filter(x => !x.lastAccessed)

  const nextTaskVariant = weaverFlags['MW-2581-standardise-next-step-task-display'] ? 'detailed' : 'simple'

  const renderLeadsSection = () => {
    if (currentTeam?.type !== TeamType.Contractor) return null

    if (leads.length < 1) {
      return (
        <div>
          <div className={`${Styles.label} ion-padding-horizontal`}>
            {weaverFlags['MW-2604-copy-change-lead-to-tender'] ? 'Tenders' : 'Leads'} {newLeads.length > 0 ? <IonBadge>{newLeads.length}</IonBadge> : null}
          </div>
          <IonCard className={Styles.card} >
            <IonCardContent>
              <IonText>
                <p>{weaverFlags['MW-2604-copy-change-lead-to-tender'] ? NO_LEADS_DESCRIPTION_V3 : NO_LEADS_DESCRIPTION_V2}</p>
              </IonText>
            </IonCardContent>
          </IonCard>
        </div>
      )
    }

    return (
      <>
        <div className={`${Styles.label} ion-padding-start`}>
          {weaverFlags['MW-2604-copy-change-lead-to-tender'] ? 'Tenders' : 'Leads'} {newLeads.length > 0 ? <IonBadge>{newLeads.length}</IonBadge> : null}
        </div>

        {leads.map((lead) =>
          <IonCard className={Styles.leadCard} key={lead.id} onClick={goToLead({ id: lead.id })}>
            <div className={Styles.leadsContainer}>
              {
                lead.address?.postCode &&
                <IonThumbnail slot="start">
                  <div className={Styles.leadStaticGoogleMapContainer}>
                    <StaticGoogleMap
                      location={lead.address?.postCode}
                      size={'150x150'}
                      zoom={11}
                      scale={1}
                    />
                  </div>
                </IonThumbnail>
              }
              <IonGrid>
                <div className={Styles.leadHeader}>
                  {`${weaverFlags['MW-2604-copy-change-lead-to-tender'] ? 'Tender' : 'Lead'} - ${lead.projectTypes.map(x => ' ' + getEnumLabel(x))}`}
                </div>
                <IonRow className={Styles.leadSubtitleContainer}>
                  <div className={Styles.leadSubtitle}>
                    {`${lead.projectTypes.map(x => ' ' + getEnumLabel(x))}`}
                  </div>
                </IonRow>
                <IonRow className="ion-nowrap">
                  <div className={Styles.leadCopyContainer}>
                    <div className={Styles.money}>
                      {flexibleBudgetTextToSignificantFigures(getFlexibleProjectBudgetAsMoneyOrMoneyRange(lead, myTeam, weaverFlags['MW-2600-expand-project-creation-flow'], weaverFlags['MW-2645-adjust-flex-budget-ranges']))}
                    </div>
                  </div>
                  <div className={Styles.arrowIcon}>
                    <IonIcon color='primary' size='small' icon={arrowForwardSharp}/>
                  </div>
                </IonRow>
              </IonGrid>
            </div>
          </IonCard>,
        )}
      </>
    )
  }

  const ctaButtonsContainer = () => {
    const isArchitect = currentTeam?.type === TeamType.Architect
    return <>
      <div className={`${Styles.label} ion-padding`}>Add your projects</div>
      <div className={Styles.ctaButtonsContainer}>
        {isArchitect &&
          <IonButton fill='outline' className="ion-margin-end" onClick={() => navigateToCreateProject('Add_Project_QS_Clicked')}>
            <IonIcon slot="start" icon={peopleOutline} />
            <span slot='start'>Request a cost estimate</span>
          </IonButton>
        }
        <IonButton fill='outline' className="ion-margin-end" onClick={() => navigateToCreateProject('Add_Project_Match_Clicked')}>
          <IonIcon slot="start" icon={barChartOutline} />
          <span>Get contractor introductions</span>
        </IonButton>
        {isArchitect &&
          <IonButton fill='outline' className="ion-margin-end"  onClick={() => navigateToCreateProject('Add_Project_Vet_Clicked')}>
            <IonIcon slot="start" icon={shieldCheckmarkOutline} />
            <span slot='start'>Verify a builder</span>
          </IonButton>
        }
      </div>
    </>
  }

  const renderProjectsSection = () => {
    const isContractor = currentTeam?.type === TeamType.Contractor
    if (projects.length < 1) return (
      <div className={Styles.noResultsContainer}>
        {!isContractor && ctaButtonsContainer()}
        <div className={`${Styles.label} ion-padding-horizontal ion-padding-top`}>Projects</div>
        <IonCard className={Styles.card}>
          <IonCardContent>
            <IonText>
              <p>{isContractor
                ? weaverFlags['MW-2604-copy-change-lead-to-tender']
                  ? NO_PROJECTS_DESCRIPTION_CONTRACTOR_V1
                  : NO_PROJECTS_DESCRIPTION_CONTRACTOR
                : NO_PROJECTS_DESCRIPTION_OWNER}</p>
            </IonText>
          </IonCardContent>
        </IonCard>
      </div>
    )
    return <>
      {!isContractor && ctaButtonsContainer()}
      <div className={`${Styles.label} ion-padding`}>Projects</div>
      {projects.map((project) =>
        <IonCard className={Styles.projectCard} key={project.id} routerLink={`/projects/${project.id}`}>
          <div className={Styles.projectContainer}>
            <IonGrid className='ion-no-padding'>
              <IonRow>
                <div className={Styles.projectStaticGoogleMapContainer}>
                  <StaticGoogleMap
                    location={project.address}
                    size={'730x210'}
                    zoom={15}
                    scale={2}
                  />
                </div>
              </IonRow>
              <div className={`${weaverFlags['MW-2422-tasks-qol1-part3'] ? Styles.projectTitleContainerV2 : Styles.projectTitleContainer}`}>
                <div>
                  <div className={Styles.projectHeader}>
                    {`${project.title}, ${project.address?.postTown}, ${project.address?.postCode}`}
                  </div>
                  <IonRow>
                    <div className={`${Styles.projectSubtitle}`}>
                      {`${project.projectTypes.map(x => ' ' + getEnumLabel(x))}`}
                    </div>
                  </IonRow>
                </div>
                <IonRow className={weaverFlags['MW-2581-standardise-next-step-task-display'] ? Styles.nextTaskButtonRow : undefined}>
                  { weaverFlags['tasks'].enabled
                    ? weaverFlags['MW-2422-tasks-qol1-part3']
                      ? <NextTaskButton
                        className={`${Styles.nextTaskButtonV2} ${Styles.extraWidthV2} ion-padding-end`}
                        projectId={project.id}
                        expand="block"
                        color='primary'
                        variant={nextTaskVariant}
                        fill='outline'/>
                      : <NextTaskButton
                        className={`${Styles.nextTaskButton} ${Styles.extraWidth} ion-padding-end`}
                        projectId={project.id}
                        color='primary'
                        fill='outline'/>
                    : <></>}
                </IonRow>
              </div>
              <IonRow>
                <div className={Styles.projectCopyContainer}>
                  {weaverFlags['tasks'].enabled
                    ? <>
                      <div className={Styles.projectStatusText}>
                        <NextTopLevelTaskDisplay projectId={project.id} />
                      </div>
                      <div className={Styles.projectDivider}>
                        |
                      </div>
                    </>
                    :<></>}
                  <div className={Styles.projectMoney}>
                    {getProjectBudgetDescription(project, myTeam, "summary", weaverFlags['MW-2600-expand-project-creation-flow'], weaverFlags['MW-2645-adjust-flex-budget-ranges'])}
                  </div>
                </div>
                <div>
                  <IonIcon className={Styles.arrowForwardIcon} color='primary' size='small' icon={arrowForwardSharp}/>
                </div>
              </IonRow>
            </IonGrid>
          </div>
        </IonCard>,
      )
      }
    </>
  }

  return <WeaverIonPage id='ProjectsIndexPage'>
    <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
      <GlobalHeader pageTitle='Projects'/>
    </WeaverIonHeader>
    <WeaverIonContent fullscreen>
      <IonList>
        {renderLeadsSection()}
        {renderProjectsSection()}
      </IonList>
    </WeaverIonContent>
  </WeaverIonPage>
}

export default ProjectsIndexPage
