import { useGraphQLDataSource } from '../../../api/graphql'
import { ShowProjectQuery, useCreateStripeSessionForStartMatchingPaymentMutation } from '../../../graphql/generated'
import { StripeProduct } from '../../../graphql/generated'
import { useIonRouter } from '@ionic/react'
import { useMyIndividualActiveTeam } from '../../providers/MyIndividualProvider/MyIndividualProvider'
import { useAnalyticsEvent } from '../../providers/SegmentProvider/hooks'
import { getBudgetRangeByMoney } from '../../../domains/profile/ContractorLeadPreferences/ContractorBudgetRanges/budgetRanges'

type MakeStripePaymentProps = {
  project: ShowProjectQuery["getProject"],
  product: StripeProduct,
  taskId: string,
  isPremium: boolean,
  priceId?: string,
}

type UseStripePaymentForLeadPaymentHandlerReturns = () => Promise<void>

/**
 * Creates a stripe session and returns a function to open the stripe window.
 *
 * Mounting this hook will cause a stripe session to be created. The stripe session can't be made in response to the click because otherwise, the stripe popup might be blocked by popup blockers
 * First found in safari, see https://weaver.atlassian.net/browse/MW-1884
 */
const useStripeStartMatchingPaymentHandler = ({
  project,
  product,
  taskId,
  isPremium,
  priceId,
}: MakeStripePaymentProps): UseStripePaymentForLeadPaymentHandlerReturns => {
  const router = useIonRouter()
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })

  const createStripeSessionForStartMatchingPayment = useCreateStripeSessionForStartMatchingPaymentMutation(gqlDataSource)
  const fireStartMatchingPremiumPaymentLaunched = useAnalyticsEvent("Project_Payment_CheckoutSession_Launched")

  const activeTeam = useMyIndividualActiveTeam()
  const urlRoot = window.location.origin
  const redirectBaseURL = `${urlRoot}/tasks/${taskId}/project/${project.id}/startMatching/stripe/`

  return async () => {

    if (!activeTeam?.id === undefined) {
      console.debug(`[useStripePaymentForLeadPaymentHandler.makeStripePayment] No active team!`, activeTeam)
      throw new Error('[useStripePaymentForLeadPaymentHandler] Need to be a member of a team to make payments!')
    }

    if (!redirectBaseURL) throw new Error('Cannot create Stripe session without a redirectBaseURL')
    if (!project.id) throw new Error('Cannot create Stripe session without a project id')

    const stripeSession = await createStripeSessionForStartMatchingPayment.mutateAsync({
      product,
      projectId: project.id,
      titleOfProject: project.title,
      redirectBaseURL,
      taskId,
    })

    const data = stripeSession.createStripeSessionForStartMatchingPayment
    const checkoutSessionUrl = data?.url
    const checkoutSessionId = data?.id

    await fireStartMatchingPremiumPaymentLaunched({
      projectId: project.id,
      checkoutSessionId,
      budget: project.budgetValue,
      budgetRange: getBudgetRangeByMoney(project.budgetValue),
      stripePriceId: priceId,
      isPremium,
    })

    if (checkoutSessionUrl == null) {
      console.debug(`[useStripePaymentForLeadPaymentHandler.makeStripePayment] createStripeSession failed to return url`, data)
      throw new Error('[useStripePaymentForLeadPaymentHandleraymentHandler] Unable to create stripe session!')
    }

    router.push(checkoutSessionUrl)
  }
}

export default useStripeStartMatchingPaymentHandler
