import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonIcon, useIonAlert } from "@ionic/react"
import { addOutline, megaphone } from "ionicons/icons"
import Styles from './InviteHomeownerPaymentModal.module.scss'
import React, { useState } from "react"
import InviteMemberModal from "../../../../common/components/InviteMemberModal/InviteMemberModal"
import { ShowProjectQuery, TeamType, useShowProjectQuery } from "../../../../graphql/generated"
import { useAnalyticsEvent } from "../../../../api/providers/SegmentProvider/hooks"
import { useMyIndividualActiveTeam } from "../../../../api/providers/MyIndividualProvider/MyIndividualProvider"
import { useQueryClient } from "@tanstack/react-query"
import { useGetInviteUrl } from "../../../projects/common"

type InviteMemberModalProps = {
  projectId: string,
  nudgeSentRef: React.MutableRefObject<boolean>,
  project: ShowProjectQuery["getProject"],
}

export const InviteHomeOwnerPaymentModal: React.FC<InviteMemberModalProps> = ({ projectId, nudgeSentRef, project }) => {

  const [ inviteHomeowner, setInviteHomeowner ] = useState(false)
  const [ nudgeSent, setNudgeSent ] = useState(false)
  const [ present ] = useIonAlert()
  const myTeam = useMyIndividualActiveTeam()
  const fireNudgeEvent = useAnalyticsEvent('Project_Payment_Nudge_Clicked')
  const queryClient = useQueryClient()
  const getInviteUrl = useGetInviteUrl()

  const membersPresentOnProject = project.members?.map(member => member.team.type)
  const hasHomeownerBeenInvited = project.memberInvites.find((member) => member.requiredTeamType === TeamType.Homeowner)
  const isHomeownerPresentOrInvited = membersPresentOnProject.includes(TeamType.Homeowner) || !!hasHomeownerBeenInvited

  const closeModalAndInvalidateQuery = async () => {
    setInviteHomeowner(false)
    await queryClient.invalidateQueries({ queryKey: useShowProjectQuery.getKey({ id: projectId }) })
  }

  const handleInviteHomeownerClick = () => {
    if (isHomeownerPresentOrInvited){
      if (hasHomeownerBeenInvited === undefined) return console.error('[Invite Homeowner payment modal]: Missing invite data')
      setNudgeSent(true) // To cause re-render of component so we have updated nudgeSentRefState

      nudgeSentRef.current = true

      fireNudgeEvent({
        projectId,
        teamId: myTeam?.id,
        inviteId: hasHomeownerBeenInvited?.id,
        inviteEmail: hasHomeownerBeenInvited?.email ?? '',
        inviteFamilyName: hasHomeownerBeenInvited?.familyName ?? '',
        inviteGivenName: hasHomeownerBeenInvited?.givenName ?? '',
        invitePhone: hasHomeownerBeenInvited?.phone ?? '',
        inviteUrl: getInviteUrl(hasHomeownerBeenInvited?.id),
        projectBudget: project.budgetValue,
        projectName: project.title,
        projectDescription: project.description,
        projectTypes: project.projectTypes,
        source: "startMatchingTask",
      })
    } else {
      setInviteHomeowner(true)
    }
  }

  const inviteSuccessAlert = () => present({
    header: 'Invite sent',
    message: 'We have sent an invite email to the address you provided.',
    buttons: [
      'Confirm',
      { text: 'OK', handler: () => closeModalAndInvalidateQuery() },
    ],
  })

  return <>
    <IonCard className={Styles.homeownerModalButtonContainer}>
      <IonCardHeader className={Styles.homeownerModalCardHeader}>
        {isHomeownerPresentOrInvited
          ? <IonCardTitle className={Styles.homeownerModalButtonTitle}>Ready to match with contractors?</IonCardTitle>
          : <IonCardTitle className={Styles.homeownerModalButtonTitle}>Want the homeowner to pay?</IonCardTitle>
        }
      </IonCardHeader>
      <IonCardContent className={Styles.homeownerModalCardContent}>
        <IonButton disabled={nudgeSentRef?.current} expand="block" fill="outline"  className={Styles.homeownerModalButton} onClick={() => handleInviteHomeownerClick()}>
          <IonIcon slot="start" icon={isHomeownerPresentOrInvited ? megaphone : addOutline}/>
          {isHomeownerPresentOrInvited
            ? <p>Let homeowner know</p>
            : <p>Invite homeowner</p>
          }
        </IonButton>
      </IonCardContent>
    </IonCard>
    <InviteMemberModal onDidDismiss={() => setInviteHomeowner(false)} isOpen={inviteHomeowner} onSuccess={() => inviteSuccessAlert()} analyticsSource='startMatchingTask' requiredTeamType={TeamType.Homeowner} projectId={projectId} />
  </>
}
