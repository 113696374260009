import React from 'react'
import useStripeStartMatchingPaymentHandler from '../../../../api/thirdParty/stripe/useStripeStartMatchingPaymentHandler'
import { ShowProjectQuery, StripeProduct, useGetStripeProductConfigsQuery } from '../../../../graphql/generated'
import SingleClickButton from '../../../../common/components/SingleClickButton'
import LoadingSpinner from '../../../../common/components/LoadingSpinner'
import ErrorBlock from '../../../../common/components/ErrorBlock'
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle } from '@ionic/react'
import { CheckMarkItem } from './CheckMarkItem'
import Styles from './Payment.module.scss'
import { Duration } from 'luxon'
import { useGraphQLDataSource } from '../../../../api/graphql'
import { moneyToText } from '../../../../common/utils/currency'

type BasicPaymentCardProps = {
  project:ShowProjectQuery["getProject"],
  taskId?: string,
  isPaymentLoading?: boolean,
}

export type CheckMarkItemType = {
  iconColor: string,
  description: string,
}

const BasicPaymentCard : React.FC<BasicPaymentCardProps> = ({ project, taskId, isPaymentLoading = false }) => {

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const getStripeProductConfigsQuery = useGetStripeProductConfigsQuery(gqlDataSource, {}, {
    staleTime: Duration.fromObject({ minutes: 5 }).as('milliseconds'),
  })

  if (taskId === undefined) {
    return <ErrorBlock name="Task ID is required for Basic start matching payment" />
  }

  const checkMarkItems: Array<CheckMarkItemType> = [
    { iconColor: 'success', description: 'Satisfaction money back guarantee' },
    { iconColor: 'success', description: 'Start off your projects with a RICS-standard independent cost estimate' },
    { iconColor: 'success', description: 'Get matched with 3 contractors from Weaver network of contractors' },
    { iconColor: 'success', description: 'Help you agree terms with your builder (access to our contract templates)' },
    { iconColor: 'light', description: 'Cut costs and save time with Weaver bid levelling service' },
    { iconColor: 'light', description: 'Dedicated Customer success rep assigned to ensure flawless tendering experience' },
  ]

  const data = getStripeProductConfigsQuery.data

  const basicConfig = data?.getStripeProductConfigs.find((config) => config.product === StripeProduct.BasicStartMatchingPayment)
  const paymentFee = moneyToText(basicConfig?.price)

  const makeStripePayment= useStripeStartMatchingPaymentHandler({
    product: StripeProduct.BasicStartMatchingPayment,
    project,
    taskId: taskId,
    priceId: basicConfig?.priceId,
    isPremium: false,
  })

  const handlePaymentClicked = async () => {
    await makeStripePayment()
  }

  if (isPaymentLoading) {
    return <LoadingSpinner name="BasicPaymentCard"/>
  }

  return <>
    <IonCard className={`${Styles.paymentCard} ion-padding`}>
      <div className='ion-text-center'>
        <IonCardHeader>
          <IonCardTitle>Basic tendering</IonCardTitle>
          <span className={Styles.paymentFee}>
            {paymentFee}
          </span>
          <IonCardContent className={Styles.cardContentText}>Recommended if you have professional support already</IonCardContent>
        </IonCardHeader>
        <SingleClickButton disabled={isPaymentLoading} onClick={handlePaymentClicked} >
          Choose Basic tendering
        </SingleClickButton>
      </div>
      {checkMarkItems.map((item, key) => (
        <CheckMarkItem key={key} iconColor={item.iconColor} description={item.description} />
      ))}
    </IonCard>
  </>
}

export default BasicPaymentCard
