import { IonButton, IonIcon, IonItem, IonLabel } from '@ionic/react'
import React, { useState } from 'react'
import ProjectAvatar from './ProjectAvatar'
import Styles from "./ShowProjectPage.module.scss"
import { useMyIndividualActiveTeam } from '../../api/providers/MyIndividualProvider/MyIndividualProvider'
import { ShowProjectInvite } from './ShowProjectTypes'
import { capitalizeFirstCharacter } from '../../common/utils/string'
import { PublicProfileModal } from '../profile/PublicProfileModal'
import UnclaimedInviteFakeProfileModal from './UnclaimedInviteFakeProfileModal/UnclaimedInviteFakeProfileModal'
import { teamTypeLabels } from '../../common/components/InviteMemberModal/inviteMemberTeamTypeLabels.i18n'
import { pageConfig_Profile_Public, useRouteTo } from '../../routes'
import { useWeaverFlags } from '../../api/thirdParty/launchDarkly/useWeaverFlags'
import { sendOutline } from 'ionicons/icons'
import { useAnalyticsEvent } from '../../api/providers/SegmentProvider/hooks'
import { ShowProjectQuery, TeamType } from '../../graphql/generated'
import { useGetInviteUrl } from './common'
import { isMobileScreenSize } from '../../common/utils/ionic'

type ProjectInviteRowProps = {
  invite: ShowProjectInvite,
  project: ShowProjectQuery['getProject'],
}

const ProjectInviteRow: React.FC<ProjectInviteRowProps> = ({ invite, project }) => {
  const myTeam = useMyIndividualActiveTeam()
  const goToMemberProfile = useRouteTo(pageConfig_Profile_Public.path)
  const weaverFlags = useWeaverFlags()
  const getinviteUrl = useGetInviteUrl()

  const projectHomeownerInvitedResetEvent = useAnalyticsEvent('Project_Homeowner_Invited_Resent')
  const projectArchitectInvitedResetEvent = useAnalyticsEvent('Project_Architect_Invited_Resent')
  const projectContractorInvitedResetEvent = useAnalyticsEvent('Project_Contractor_Invited_Resent')
  const profileMemberClickedEvent = useAnalyticsEvent('Project_Members_Profile_Clicked')

  const [ showProfileModal, setShowProfileModal ] = useState<boolean>(false)
  const [ hasResentInvite, setHasResentInvite ] = useState(false)

  if (!myTeam) throw new Error("Can't render this component without an active team")

  const companyName = invite.team?.name || invite.companyName || teamTypeLabels[invite.requiredTeamType]
  const claimedInviteTeam = invite.team ? invite.team : null

  const handleClick = () => {
    profileMemberClickedEvent({
      viewedTeamId: claimedInviteTeam?.id ?? '',
      viewedTeamType: claimedInviteTeam?.type,
      projectId: project.id,
    })

    if (weaverFlags['tab-nav-bar'].enabled){
      claimedInviteTeam
        ? goToMemberProfile({ teamType: claimedInviteTeam.type, teamId: claimedInviteTeam.id })()
        : setShowProfileModal(true)
    } else {
      setShowProfileModal(true)
    }
  }

  const handleResendInvite = () => {
    switch (invite.requiredTeamType) {
      case TeamType.Homeowner:
        projectHomeownerInvitedResetEvent({
          projectId: project.id,
          inviteId: invite.id,
          source: 'showProject',
          inviteUrl: getinviteUrl(invite.id),
          inviteEmail: invite.email ?? '',
          invitePhone: invite.phone ?? '',
          inviteGivenName: invite.givenName ?? '',
          inviteFamilyName: invite.familyName ?? '',
          projectName: project.title,
          projectBudget: project.budgetValue,
          projectDescription: project.description,
          projectTypes: project.projectTypes,
        })
        break
      case TeamType.Architect:
        projectArchitectInvitedResetEvent({
          projectId: project.id,
          inviteId: invite.id,
          source: 'showProject',
          inviteUrl: getinviteUrl(invite.id),
          inviteEmail: invite.email ?? '',
          invitePhone: invite.phone ?? '',
          inviteGivenName: invite.givenName ?? '',
          inviteFamilyName: invite.familyName ?? '',
          inviteCompanyName: invite.companyName ?? '',
          projectName: project.title,
          projectBudget: project.budgetValue,
          projectDescription: project.description,
          projectTypes: project.projectTypes,
        })
        break
      case TeamType.Contractor:
        projectContractorInvitedResetEvent({
          projectId: project.id,
          inviteId: invite.id,
          source: 'showProject',
          inviteUrl: getinviteUrl(invite.id),
          inviteEmail: invite.email ?? '',
          invitePhone: invite.phone ?? '',
          inviteCompanyName: invite.companyName ?? '',
          inviteGivenName: invite.givenName ?? '',
          inviteFamilyName: invite.familyName ?? '',
          projectName: project.title,
          projectBudget: project.budgetValue,
          projectDescription: project.description,
          projectTypes: project.projectTypes,
        })
        break
      default:
        console.error('[ProjectInviteRow]: Unknown TeamType')
        break
    }

    setHasResentInvite(true)
  }

  return (
    <>
      <IonItem className={Styles.projectMemberItem} lines="full">
        <ProjectAvatar slot="start" title={companyName} onClick={handleClick} />
        <div onClick={handleClick} className={Styles.clickableTextArea}>
          <IonLabel>
            <p className={Styles.projectMemberLabel}>{companyName}</p>
          </IonLabel>
          <IonLabel className={Styles.projectMemberType}>
            <p>Invited: {capitalizeFirstCharacter(invite.requiredTeamType)}</p>
          </IonLabel>
        </div>
        {invite.referral?.teamId === myTeam.id &&
        <IonButton disabled={hasResentInvite} size='large' fill='outline' slot='end' className={Styles.resendInviteButton} onClick={handleResendInvite}>
          <p className={`ion-hide-sm-down ${Styles.rowButtonLabels}`}>Resend Invite</p>
          <IonIcon className={Styles.sendIcon} slot={isMobileScreenSize() ? '' : 'start'} icon={sendOutline} />
        </IonButton>
        }
      </IonItem>
      {claimedInviteTeam && <PublicProfileModal
        team={claimedInviteTeam}
        breakpoints={[ 0, 0.5, 0.75, 0.95 ]}
        initialBreakpoint={0.95}
        isOpen={showProfileModal}
        canDismiss={true}
        onDidDismiss={() => setShowProfileModal(false)}
      />}
      {!claimedInviteTeam && <UnclaimedInviteFakeProfileModal
        invite={invite}
        breakpoints={[ 0, 0.5, 0.75, 0.95 ]}
        initialBreakpoint={0.95}
        isOpen={showProfileModal}
        canDismiss={true}
        onDidDismiss={() => setShowProfileModal(false)}
      />}
    </>
  )
}

export default ProjectInviteRow
