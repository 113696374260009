import React, { useState } from 'react'
import { useGetStripePaymentLastDigitsCardQuery, useGetStripeProductConfigsQuery, useShowLeadQuery, useUpdateStripePaymentDetailsMutation } from "../../../graphql/generated"
import { useGraphQLDataSource } from '../../../api/graphql'
import LoadingSpinnerPage from '../../../common/components/LoadingSpinner/LoadingSpinnerPage'
import { alwaysArray } from '../../../common/utils'
import { FeeOption } from './ContractorLeadAcceptorPage'
import { budgetRangeLeadFeeStripeProduct, budgetRangeMonthlySubscriptionStripeProduct, getBudgetRangeByMoney } from '../../profile/ContractorLeadPreferences/ContractorBudgetRanges/budgetRanges'
import { useParamsFromPageConfig } from '../../../routesProvider'
import ErrorBlockPage from '../../../common/components/ErrorBlock/ErrorBlockPage'
import PaymentPlanPickerPage from './PaymentPlanPickerPage'
import { budgetRangeLabels } from '../../profile/ContractorLeadPreferences/ContractorBudgetRanges/budgetRanges.i18n'
import { moneyToText } from '../../../common/utils/currency'
import { Duration } from 'luxon'
import { pageConfig_Projects_AcceptLead_SingleLeadPayment, useRouteTo } from '../../../routes'
import useRedirectToStripePaymentForLeadPaymentHandler from '../../../api/thirdParty/stripe/useRedirectToStripePaymentHandler'
import { useMyContractorProfile } from '../../profile/ContractorLeadPreferences/ContractorBudgetRanges/datasource'
import FreeLeadAcceptancePage from './FreeLeadAcceptancePage'
import { shouldPayForLead } from '../common'
import { useAnalyticsEvent } from '../../../api/providers/SegmentProvider/hooks'
import { useWeaverFlags } from '../../../api/thirdParty/launchDarkly/useWeaverFlags'
import { IonToast } from '@ionic/react'
import Styles from './DisplayPaymentPlanPickerPage.module.scss'
import { useRedirectBaseURL } from '../../../common/hooks/useRedirectBaseURL'

type paymentProcessingState = {
  isProcessing: boolean,
  result: 'error' | 'processing' | undefined,
  toastMessage: undefined | string,
}

const DisplayPaymentPlanPicker: React.FC = () => {
  const { ["MW-2603-ppl-test-changes"]: pplTestChanges } = useWeaverFlags()
  const { leadId } = useParamsFromPageConfig<{ leadId: string }>()
  const weaverFlags = useWeaverFlags()
  const [ subscriptionIsProcessing, setSubscriptionIsProcessing ] = useState<paymentProcessingState>({
    isProcessing: false,
    result: undefined,
    toastMessage: undefined,
  })
  const [ pptIsProcessing, setPPTIsProcessing ] = useState<paymentProcessingState>({
    isProcessing: false,
    toastMessage: undefined,
    result: undefined,
  })

  const [ isGettingUrlForStripePaymentUpdate, setIsGettingUrlForStripePaymentUpdate ] = React.useState(false)

  const redirectUrl = useRedirectBaseURL('/')
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const useShowLeadResponse = useShowLeadQuery(gqlDataSource , { id: leadId }, {
    staleTime: Duration.fromObject({ day: 1 }).as('milliseconds'),
  })
  const getStripeProductConfigsQuery = useGetStripeProductConfigsQuery(gqlDataSource, {}, {
    staleTime: Duration.fromObject({ day: 1 }).as('milliseconds'),
  })

  const { data: lastDigitsCard } =  useGetStripePaymentLastDigitsCardQuery(gqlDataSource)
  const updateStripePaymentDetailsMutation = useUpdateStripePaymentDetailsMutation(gqlDataSource)
  const budgetValueAsMoney = useShowLeadResponse.data?.getLead.budgetValue
  const lead = useShowLeadResponse.data?.getLead
  const leadBudgetRange = getBudgetRangeByMoney(budgetValueAsMoney)    // NOTE: `leadBudgetAmountInPennies` should never be undefined/null because of the guard

  const { getContractorProfileQuery: useGetContractorProfileResponse } = useMyContractorProfile()
  const contractorProfile = useGetContractorProfileResponse.data?.getContractorProfile

  const makeStripePayment = useRedirectToStripePaymentForLeadPaymentHandler({ leadId, source: 'DisplayPaymentPlanPicker', cardIsOnFile: !!lastDigitsCard?.getStripePaymentLastDigitsCard })
  const routeToSingleLeadPayment = useRouteTo(pageConfig_Projects_AcceptLead_SingleLeadPayment.path)

  const triggerEvent_SingleLeadPayment_Clicked = useAnalyticsEvent("Lead_SingleLeadPayment_Clicked")
  const triggerEvent_Lead_Payment_Failed_Change_Clicked = useAnalyticsEvent("Lead_Payment_Failed_Change_Clicked")
  const triggerEvent_Lead_Payment_Card_Change_Clicked = useAnalyticsEvent("Lead_Payment_Card_Change_Clicked")
  const triggerEvent_Lead_Payment_ExpressCheckout_Clicked = useAnalyticsEvent("Lead_Payment_ExpressCheckout_Clicked")

  if (useShowLeadResponse.isLoading || useGetContractorProfileResponse.isLoading || getStripeProductConfigsQuery.isLoading) {
    return <LoadingSpinnerPage name="DisplayPaymentPlanPicker" />
  }

  if (useShowLeadResponse.error || useGetContractorProfileResponse.error || getStripeProductConfigsQuery.error || lead == null || contractorProfile == null || leadBudgetRange == null) {
    console.error(`[DisplayPaymentPlanPicker] Failed to load lead and Stripe product data: `, { useShowLeadResponse, useGetContractorProfileResponse, getStripeProductConfigsQuery, budgetValueAsMoney, leadBudgetRange })
    return <ErrorBlockPage name='DisplayPaymentPlanPicker' onRefresh={() => {
      useShowLeadResponse.refetch()
      useGetContractorProfileResponse.refetch()
      getStripeProductConfigsQuery.refetch()
    }} />
  }

  const stripeProductLeadFee = alwaysArray(getStripeProductConfigsQuery.data?.getStripeProductConfigs)
    .find(each => each.product === budgetRangeLeadFeeStripeProduct[leadBudgetRange])
  const budgetRangeLeadFee = moneyToText(stripeProductLeadFee?.price)

  const stripeProductMonthlySubscription = alwaysArray(getStripeProductConfigsQuery.data?.getStripeProductConfigs)
    .find(each => each.product === budgetRangeMonthlySubscriptionStripeProduct[leadBudgetRange])
  const budgetRangeMonthlySubscriptionFee = moneyToText(stripeProductMonthlySubscription?.price)

  if (stripeProductLeadFee == null|| budgetRangeLeadFee == null || stripeProductMonthlySubscription == null || budgetRangeMonthlySubscriptionFee == null) {
    console.error(`[DisplayPaymentPlanPicker] Failed to resolve fees: `, { getStripeProductConfigsQuery, stripeProductLeadFee, stripeProductMonthlySubscription })
    return <ErrorBlockPage name='DisplayPaymentPlanPicker'>
      <p>Unable to find the pricing. Please contact Weaver Support.</p>
    </ErrorBlockPage>
  }

  const changePaymentCardFromToast = async (isSubscription: boolean) => {
    await triggerEvent_Lead_Payment_Failed_Change_Clicked({
      budget: lead.budgetValue,
      budgetRange: leadBudgetRange,
      isSubscription: isSubscription,
      projectId: leadId,
      stripePriceId: isSubscription ? stripeProductMonthlySubscription.priceId : stripeProductLeadFee.priceId,
    })
    await handleManageBillingRequest()
  }

  const changePaymentCard = async () => {
    await triggerEvent_Lead_Payment_Card_Change_Clicked({
      budget: lead.budgetValue,
      budgetRange: leadBudgetRange,
      projectId: leadId,
    })
    await handleManageBillingRequest()
  }

  const handleManageBillingRequest =  async () => {
    setIsGettingUrlForStripePaymentUpdate(true)
    if (!redirectUrl) return console.error('[DisplayPaymentPlanPicker] Failed to get redirect url')
    const stripePageForUpdateDetails = await updateStripePaymentDetailsMutation.mutateAsync({ redirectUrl: redirectUrl })

    if (!stripePageForUpdateDetails.updateStripePaymentDetails.url) return console.error('[DisplayPaymentPlanPicker] Failed to get stripe url')

    setIsGettingUrlForStripePaymentUpdate(false)
    window.location.href = stripePageForUpdateDetails.updateStripePaymentDetails.url
  }

  const handleNextClick = async (feeOption: FeeOption) => {
    if (lastDigitsCard?.getStripePaymentLastDigitsCard) {
      await triggerEvent_Lead_Payment_ExpressCheckout_Clicked({
        budget: lead.budgetValue,
        budgetRange: leadBudgetRange,
        projectId: leadId,
        isSubscription: feeOption === FeeOption.Subscription ? true : false,
        stripePriceId: feeOption === FeeOption.Subscription ? stripeProductMonthlySubscription.priceId : stripeProductLeadFee.priceId,
      })
    }
    switch (feeOption) {
      case FeeOption.Subscription: {
        try {
          if (lastDigitsCard?.getStripePaymentLastDigitsCard) {
            setSubscriptionIsProcessing({
              isProcessing: true,
              result: 'processing',
              toastMessage: 'Payment is processing',
            })
          }

          await makeStripePayment(stripeProductMonthlySubscription)
          if (lastDigitsCard?.getStripePaymentLastDigitsCard) {
            setTimeout(() => {
              setSubscriptionIsProcessing({
                isProcessing: false,
                result: undefined,
                toastMessage: undefined,
              })
            }, 5000)
          }

        } catch (error) {
          let extractedErrorMessage
          if (typeof error === 'object') {
            extractedErrorMessage =  error?.toString()
            if (extractedErrorMessage?.split('Error:')[1]?.trim()) {
              setSubscriptionIsProcessing({
                isProcessing: false,
                result: 'error',
                toastMessage: extractedErrorMessage?.split('Error:')[1]?.trim(),
              })
            } else {
              setSubscriptionIsProcessing({
                isProcessing: false,
                result: 'error',
                toastMessage: extractedErrorMessage,
              })
            }
          }
        }
        break
      }
      case FeeOption.PerLead: {
        await triggerEvent_SingleLeadPayment_Clicked({
          projectId: leadId,
          budgetRange: leadBudgetRange,
        })
        if (pplTestChanges) {
          try {
            if (lastDigitsCard?.getStripePaymentLastDigitsCard) {
              setPPTIsProcessing({
                isProcessing: true,
                result: 'processing',
                toastMessage: 'Payment is processing',
              })
            }

            await makeStripePayment(stripeProductLeadFee)
            if (lastDigitsCard?.getStripePaymentLastDigitsCard) {
              setTimeout(() => {
                setPPTIsProcessing({
                  isProcessing: false,
                  result: undefined,
                  toastMessage: undefined,
                })
              }, 5000)
            }

          } catch (error) {
            let extractedErrorMessage
            if (typeof error === 'object') {
              extractedErrorMessage =  error?.toString()
              if (extractedErrorMessage?.includes('Error')) {
                setPPTIsProcessing({
                  isProcessing: false,
                  result: 'error',
                  toastMessage: extractedErrorMessage?.split('Error:')[1]?.trim(),
                })
              } else {
                setPPTIsProcessing({
                  isProcessing: false,
                  result: 'error',
                  toastMessage: extractedErrorMessage,
                })
              }
            }
          }
        } else {
          routeToSingleLeadPayment({ leadId })()
        }

        break
      }
    }
  }

  console.debug(`[DisplayPaymentPlanPicker] Render: `, {
    leadId,
    useShowLeadResponse,
    getStripeProductConfigsQuery,
    stripeProductLeadFee,
    stripeProductMonthlySubscription,
  })

  return shouldPayForLead(lead, contractorProfile, weaverFlags['MW-2600-expand-project-creation-flow'], weaverFlags['MW-2645-adjust-flex-budget-ranges'])
    ? (
      <>
        <PaymentPlanPickerPage
          leadId={lead.id}
          subscriptionIsProcessing={subscriptionIsProcessing.isProcessing}
          pptIsProcessing={pptIsProcessing.isProcessing}
          lastDigitsCard={lastDigitsCard?.getStripePaymentLastDigitsCard}
          budgetRange={leadBudgetRange}
          budgetRangeLabel={budgetRangeLabels[leadBudgetRange]}
          stripeProductLeadFee={stripeProductLeadFee}
          stripeProductMonthlySubscription={stripeProductMonthlySubscription}
          handleNextClick={handleNextClick}
          changePaymentCard={changePaymentCard}
          isGettingUrlForStripePaymentUpdate={isGettingUrlForStripePaymentUpdate}
        />

        <IonToast
          isOpen={subscriptionIsProcessing.isProcessing || pptIsProcessing.isProcessing}
          message={'Payment is processing'}
          duration={5000}
          position="bottom"
          color={'warning'}
          cssClass={Styles.toast}
        />

        <IonToast
          isOpen={!subscriptionIsProcessing.isProcessing && !pptIsProcessing.isProcessing && (subscriptionIsProcessing.result === 'error' || pptIsProcessing.result === 'error')}
          message={subscriptionIsProcessing.toastMessage ?? pptIsProcessing.toastMessage}
          duration={10000}
          position="bottom"
          color={'danger'}
          cssClass={Styles.toast}
          buttons={[
            {
              text: 'Change',
              handler: () => changePaymentCardFromToast(subscriptionIsProcessing.result === 'error'),
            },
          ]}
        />

      </>
    )
    : <FreeLeadAcceptancePage lead={lead} />
}

export default DisplayPaymentPlanPicker
