import { zodResolver } from "@hookform/resolvers/zod"
import { IonModal, IonToolbar, IonTitle, IonContent, IonGrid, IonRow, IonCol, IonInput, IonNote, useIonAlert } from "@ionic/react"
import classnames from 'classnames'
import React, { ComponentProps, useCallback, useEffect } from "react"
import { Controller, useForm, UseFormReturn } from "react-hook-form"
import { isPossiblePhoneNumber } from "react-phone-number-input"
import { z } from "zod"
import { useGraphQLDataSource } from "../../../api/graphql"
import { EventProperties, ProjectInviteCommonProperties } from "../../../api/providers/SegmentProvider/events"
import { useAnalyticsEvent } from "../../../api/providers/SegmentProvider/hooks"
import { useGetInviteUrl } from "../../../domains/projects/common"
import { ContentDisposition, ProjectMemberRole, TaskActionableType, TeamType, useInviteMemberToMyProjectMutation, useListProjectTasksQuery, useShowProjectQuery } from "../../../graphql/generated"
import PhoneInput from 'react-phone-number-input'

import SingleClickButton from "../SingleClickButton"

import Styles from "./InviteMemberModal.module.scss"
import { teamTypeLabels } from "./inviteMemberTeamTypeLabels.i18n"
import { useAsyncQuery } from "../../hooks/query"
import { Duration } from "luxon"
import { useCompleteBackgroundTask } from "../../utils/tasks"

const DEFAULT_MODAL_BREAKPOINT = 0.93

const zInviteMemberForm = z.object({
  requiredTeamType: z.nativeEnum(TeamType),
  companyName: z.string().optional(),
  givenName: z.string().nonempty("Required"),
  familyName: z.string().nonempty("Required"),
  email: z.string().nonempty("Required").email(),
  phone: z.string().nonempty("Required")
    .refine((x) => isPossiblePhoneNumber(x, "GB"), "Must be a valid phone number"),
}).superRefine((data, ctx) => {
  if (data.requiredTeamType !== TeamType.Homeowner && !data.companyName) {
    ctx.addIssue({
      path: [ "companyName" ],
      code: z.ZodIssueCode.custom,
      message: "Required",
    })
  }
})
type InviteMemberForm = z.infer<typeof zInviteMemberForm>
type InviteMemberToMyProjectMutationFn = typeof useInviteMemberToMyProjectMutation
export type InviteMemberOnSuccessHandler = NonNullable<Parameters<InviteMemberToMyProjectMutationFn>[1]>["onSuccess"]

type InviteMemberModalProps = {
  /** Called when the inviteMember mutation completes successfully
   *
   * Use this callback to close the modal, refresh your cache and send analytics events */
  onSuccess?: InviteMemberOnSuccessHandler,

  /** TeamType to invite. Contractors will be invited as `ProjectMemberRole.CandidateProfessional`, All other roles are invited as `ProjectMemberRole.Owner`
   *
   * undefined is an invalid state, but it is allowed so that the consumer does not have to set a default when the modal is closed.
   */
  requiredTeamType?: TeamType | undefined,
  projectId: string,
  /** provide a string to indicate the consuming area. You can expand this list in the `source` property of the dependent events  */
  analyticsSource: (EventProperties["Project_Architect_Invited"] | EventProperties["Project_Homeowner_Invited"] | EventProperties["Project_Invite_Created"])["source"],
} & ComponentProps<typeof IonModal>

type InviteMemberFormSubmitFn = Parameters<UseFormReturn<InviteMemberForm>["handleSubmit"]>[0]

/**
 * InviteMemberModal is a component for inviting members to _your_ project
 *
 * Because it is wired to the `inviteMemberToMyProject` Mutation, it makes assumptions specific to an owner inviting other members to the project.
 *
 * This component sends analytics events on your behalf.
 */
const InviteMemberModal: React.FC<InviteMemberModalProps> =({ requiredTeamType, projectId, analyticsSource, onSuccess = () => undefined, ...ionModalProps }) => {
  if (!ionModalProps.initialBreakpoint) {
    ionModalProps.initialBreakpoint = DEFAULT_MODAL_BREAKPOINT
  }
  if (!ionModalProps.breakpoints) {
    ionModalProps.breakpoints = [ 0, DEFAULT_MODAL_BREAKPOINT ]
  }
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const [ present ] = useIonAlert()
  const getInviteUrl = useGetInviteUrl()
  const requiredProjectRole = requiredTeamType === TeamType.Contractor ? ProjectMemberRole.CandidateProfessional : ProjectMemberRole.Owner
  const teamTypeLabel = requiredTeamType ? teamTypeLabels[requiredTeamType] : "Unknown"
  const { register, reset, handleSubmit, formState, setValue, control } = useForm<InviteMemberForm>({ resolver: zodResolver(zInviteMemberForm) })
  const asyncQuery = useAsyncQuery({ api: "core" })

  const completeBackgroundTask = useCompleteBackgroundTask()

  // sync variables to defaults in ShowProjectPage + ChatOptions to take advantage of any existing query cache for these vars
  const projectVariables = {
    id: projectId,
    config: { disposition: ContentDisposition.Attachment, transformation: { width: 1000, height: 1000 } },
    ignoreScope: false,
  }
  // use refetchOnMount to ensure we use cache if available
  const showProjectQuery = useShowProjectQuery(gqlDataSource, projectVariables, { refetchOnWindowFocus: false, refetchOnMount: false })

  const getProjectWithRefetch = useCallback(async () => {
    if (!showProjectQuery.isSuccess) {
      return showProjectQuery.data?.getProject
    }
    return (await showProjectQuery.refetch()).data?.getProject
  }, [ showProjectQuery.isSuccess, showProjectQuery.data ])

  // this is a legacy event and will be deprecated by Project_Contractor_Invited
  const triggerProjectInviteCreated = useAnalyticsEvent("Project_Invite_Created")
  const triggerProjectArchitectInvited = useAnalyticsEvent("Project_Architect_Invited")
  const triggerProjectHomeownerInvited = useAnalyticsEvent("Project_Homeowner_Invited")
  const triggerProjectContractorInvited = useAnalyticsEvent("Project_Contractor_Invited")

  const inviteMemberMutation = useInviteMemberToMyProjectMutation(gqlDataSource, {
    onError: (err) => {
      if (!(err instanceof Error)) return
      present({
        header: `Oops! We couldn't invite your ${teamTypeLabel}.`,
        message: err.message,
        buttons: [
          {
            text: "Dismiss",
            role: 'cancel',
          },
        ],
      })

    },
    onSuccess: async (data, variables, context) => {
      await onSuccess(data, variables, context)
      const inviteUrl = getInviteUrl(data.inviteToMyProject.id)

      const projectData = await getProjectWithRefetch()
      if (!projectData) throw new Error("[InviteMemberModal] Failed to fetch project data")

      const commonEventProps: ProjectInviteCommonProperties = {
        inviteId: data.inviteToMyProject.id,
        inviteUrl: inviteUrl,
        inviteEmail: variables.input.email,
        inviteFamilyName: variables.input.familyName,
        inviteGivenName: variables.input.givenName,
        invitePhone: variables.input.phone,
        projectId,
        projectName: projectData.title,
        projectBudget: projectData.budgetValue,
        projectDescription: projectData.description,
        projectTypes: projectData.projectTypes,
        source: analyticsSource,
      }

      if (variables.requiredTeamType === TeamType.Homeowner) {
        triggerProjectHomeownerInvited({
          ...commonEventProps,
        })
        await completeBackgroundTask(projectId, (t => t.actionableType === TaskActionableType.InviteHomeowner))
      } else if (variables.requiredTeamType === TeamType.Architect) {
        triggerProjectArchitectInvited({
          ...commonEventProps,
          inviteCompanyName: variables.input.companyName || "Unknown Company name",
        })
        await completeBackgroundTask(projectId, (t => t.actionableType === TaskActionableType.InviteArchitect))
      } else if (variables.requiredTeamType === TeamType.Contractor) {
        // Project_Invite_Created is a legacy event and will be deprecated by Project_Contractor_Invited
        // trigger both events for backwards compatibility
        triggerProjectInviteCreated({
          ...commonEventProps,
          inviteCompanyName: variables.input.companyName || "Unknown Company name",
        })
        triggerProjectContractorInvited({
          ...commonEventProps,
          inviteCompanyName: variables.input.companyName || "Unknown Company name",
        })
      }
      reset({
        companyName: "",
        email: "",
        familyName: "",
        givenName: "",
        phone: "",
        requiredTeamType: requiredTeamType,
      })
    },
  })

  // set form hidden field to required team type so conditional validation can take place (see zInviteMemberForm)
  useEffect(() => {
    reset({
      companyName: "",
      email: "",
      familyName: "",
      givenName: "",
      phone: "",
      requiredTeamType: requiredTeamType,
    })
  }, [ requiredTeamType, setValue  ])

  const { errors } = formState

  const onFormValid: InviteMemberFormSubmitFn = async (data) => {
    const { companyName, email, familyName, givenName, phone, requiredTeamType } = data

    inviteMemberMutation.mutate({
      requiredProjectRole,
      requiredTeamType,
      input: {
        projectId,
        companyName,
        email,
        familyName,
        givenName,
        phone,
      },
    })
  }

  return <IonModal
    {...ionModalProps}>
    <IonToolbar>
      <IonTitle>Invite {teamTypeLabel}</IonTitle>
    </IonToolbar>
    <IonContent>
      <div className={`${classnames({
        [Styles.modalContainer]: true,
        [Styles.editableInputs]: true,
      })}`} >
        <p>Please enter the details of the {teamTypeLabel} you would like to invite.</p>
        <p>Your {teamTypeLabel} will receive an invitation to Weaver.</p>
        <IonGrid>
          {requiredTeamType !== TeamType.Homeowner
            ? <IonRow>
              <IonCol>
                <p>Company name*</p>
                <IonInput placeholder="Company Name" {...register("companyName")}></IonInput>
                {errors.companyName ? <IonNote color='danger'>{errors.companyName.message}</IonNote> : null}
              </IonCol>
            </IonRow>
            : null
          }

          <IonRow>
            <IonCol>
              <p>First name*</p>
              <IonInput placeholder="First Name" {...register("givenName")}></IonInput>
              {errors.givenName ? <IonNote color='danger'>{errors.givenName.message}</IonNote> : null}
            </IonCol>
            <IonCol>
              <p>Last name*</p>
              <IonInput placeholder="Last Name" {...register("familyName")}></IonInput>
              {errors.familyName ? <IonNote color='danger'>{errors.familyName.message}</IonNote> : null}
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol size="6">
              <p>Email*</p>
              <IonInput placeholder="email@example.com" {...register("email")}></IonInput>
              {errors.email ? <IonNote color='danger'>{errors.email.message}</IonNote> : null}
            </IonCol>
            <IonCol size="6">
              <p>Phone*</p>
              <Controller
                control={control}
                name="phone"
                render={({
                  formState: { isSubmitting },
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <PhoneInput
                      data-testid="InviteMemberModal.PhoneInput"
                      className={`${classnames({
                        [Styles.phoneInput] : true,
                        [Styles.editablePhoneInput]: true,
                      })}`}
                      international
                      defaultCountry="GB"
                      value={value}
                      onChange={onChange}
                      disabled={isSubmitting}
                      error={error?.message}
                    />
                    {error ? <IonNote color='danger'>{error.message}</IonNote> : null}
                  </>
                )}
              />
            </IonCol>
          </IonRow>

          <SingleClickButton expand='block' disabled={inviteMemberMutation.isLoading} onClick={handleSubmit(onFormValid)}>Invite {teamTypeLabel}</SingleClickButton>
        </IonGrid>
      </div>
    </IonContent>
  </IonModal>
}

export default InviteMemberModal
